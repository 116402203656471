import { IEnvironmentType } from "./environmentType";

export const stagEnv: IEnvironmentType = {
    isProduction: true,
    graphqlApi: 'https://stag.api.lnrouter.app/graphql',
    lnrouter_env: 'staging',
    domain: 'stag.lnrouter.app',
    pricing: [
        {
            months: 1,
            offPercent: 0,
            monthlyPriceSat: 99000,
            id: 1,
        },
        {
            months: 3,
            offPercent: 0.1,
            monthlyPriceSat: 99000,
            id: 2,
        },
        {
            months: 6,
            offPercent: 0.2,
            monthlyPriceSat: 99000,
            id: 3,
        },
    ]
}
