import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  defaultDataIdFromObject,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import './firebaseEmulator';
import firebase from "firebase/app";
import env from "./env";


const mainLink = new BatchHttpLink({  
  uri: env.graphqlApi,
  batchMax: 1, // No more than 5 operations per batch // Disabled so far
  batchInterval: 20 // Wait no more than 20ms after first 
});
// const mainLink = new HttpLink({
//   uri: env.graphqlApi,
// });

const authMiddleware = setContext(async (req, { headers }) => {
  /**
   * Auth middleware for Apollo to attach the authentication token to each request.
   */

  const isSSR = typeof window === 'undefined';
  if (isSSR) {
    return {
      headers: {
        ...headers,
        authorization: "",
      },
    };
  }
  const user = firebase.auth().currentUser;

  if (user && !user.isAnonymous) {
    const token = await user.getIdToken();
    return {
      headers: {
        ...headers,
        authorization:`Bearer ${token}`,
      },
    };
  } else {
    return {
      headers: {
        ...headers,
        authorization: "",
      },
    };
  }
});



const client = new ApolloClient({
  link: authMiddleware.concat(mainLink),
  cache: new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'GraphEdge': return `GraphEdge:${responseObject.id}-${responseObject.direction}`; // Define cache id different because edge id is not unique.
        default: return defaultDataIdFromObject(responseObject);
      }
    }
  }),
});

export default client;
