import { IEnvironmentType } from "./environmentType";

export const devEnv: IEnvironmentType = {
    isProduction: false,
    graphqlApi: 'http://localhost:5000/graphql',
    lnrouter_env: 'development',
    domain: 'localhost',
    pricing: [
        {
            months: 1,
            offPercent: 0,
            monthlyPriceSat: 10,
            id: 1,
        },
        {
            months: 3,
            offPercent: 0.1,
            monthlyPriceSat: 9,
            id: 2,
        },
        {
            months: 6,
            offPercent: 0.2,
            monthlyPriceSat: 8,
            id: 3,
        },
    ]

}

